import styled from '@emotion/styled'
import { Flex } from './'

const Card = styled(Flex)``

Card.defaultProps = {
  boxShadow: 'default',
  borderRadius: 'default',
  bg: 'white',
  p: 4,
}

export default Card
