import React, { useState } from 'react'
// import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

import { Flex, Text } from './'
// import AriaModal from 'react-aria-modal'
// import noScroll from "no-scroll"

//consider react-aria-modal

//TODO:stolen from navbar. merge/consolidate (remove duplicate)
const menuItemActiveProps = {
  fontWeight: 'black',
  bg: 'orange-lightest',
  border: { l: 8, r: 8, t: 4, b: 4 },
}
//TODO:if styling is shared with non-mobile, may be a good candidate to extract to the styled-system theme specification
const MenuItem = ({ children, to, active, ...rest }) => {
  const activeProps = active ? menuItemActiveProps : {}
  return (
    <Text
      p={2}
      letterSpacing="wide"
      textTransform="uppercase"
      fontFamily="'Titillium Web', sans-serif"
      fontWeight="bold"
      border-hover={{ b: 2 }}
      font-hover="black"
      {...activeProps}
    >
      <Link swipe to={to} {...rest}>
        {children}
      </Link>
    </Text>
  )
}
const MobileMenu = ({ children, onMenuItemClicked, isMenuOpen, ...rest }) => {
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { onClick: onMenuItemClicked })
  )

  // return (
  //   <>
  //     {isMenuOpen && (
  //       <FlexContainer
  //         hidden-lg
  //         as="nav"
  //         col
  //         hAlignContent="stretch"
  //         shrink
  //         z={20}
  //         bg="white"
  //         h="screen"
  //         w="screen"
  //       >
  //         {childrenWithProps}
  //       </FlexContainer>
  //     )}
  //   </>
  // )
  return (
    <Dialog
      isOpen={isMenuOpen}
      css={{ width: '100vw', height: '100vh', margin: 0, padding: 0 }}
    >
      <Flex
        as="nav"
        col
        hAlignContent="stretch"
        shrink
        zIndex={20}
        bg="white"
        {...rest}
      >
        {childrenWithProps}
      </Flex>
    </Dialog>
  )

  // return (
  //   <>
  //     {isMenuOpen && (
  //       <Flex
  //         as="nav"
  //         col
  //         hAlignContent="stretch"
  //         shrink
  //         zIndex={20}
  //         bg="white"
  //         width="100vw"
  //         height="100vh"
  //         {...rest}
  //       >
  //         {childrenWithProps}
  //       </Flex>
  //     )}
  //   </>
  // )
}

//TODO:purgecss
export const Hamburger = ({ isActive, color, ...rest }) => (
  <button
    className={`hamburger hamburger--elastic ${isActive ? 'is-active' : ''}`}
    type="button"
    {...rest}
  >
    <span className="hamburger-box">
      <span
        className="hamburger-inner"
        css={css`
          background: ${color} !important;
          &::after,
          &::before {
            background: ${color} !important;
          }
        `}
      />
    </span>
  </button>
)

//change to button (merge with parent)

MobileMenu.MenuItem = MenuItem
MobileMenu.Hamburger = Hamburger
export default MobileMenu
