import React from 'react'
import Helmet from 'react-helmet'

import { Flex, Box } from '../'
import useSiteMetadata from '../SiteMetadata'

const DocHead = () => {
  const { title, description } = useSiteMetadata()

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/img/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-16x16.png"
        sizes="16x16"
      />

      <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="/" />
      <meta property="og:image" content="/img/og-image.jpg" />
    </Helmet>
  )
}

const PageContainer = ({ children }) => (
  <Flex col position="relative" height="100vh">
    {children}
  </Flex>
)
const Header = props => <Flex {...props} as="header" />
Header.displayName = 'Header'

//TODO: look at rebass/components for defining these as a function
const Footer = props => <Flex as="footer" {...props} />
Footer.displayName = 'Footer'

const Main = ({ children }) => (
  <Flex col grow as="main">
    {children}
  </Flex>
)
Main.displayName = 'Main'

const LayoutBase = ({ children }) => <PageContainer>{children}</PageContainer>

LayoutBase.Header = Header
LayoutBase.Footer = Footer
LayoutBase.Main = Main

export default LayoutBase
