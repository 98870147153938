import { Text } from '@roo-ui/components'

const Truncate = ({ length, children, ...rest }) => {
  let text = children
  if (children && children.length > length) {
    text = children.substring(0, length) + '...'
  }
  return <Text {...rest}>{text}</Text>
}
export default Truncate
//TODO:make sure children is just a string
