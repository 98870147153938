import { qantas as theme } from '@roo-ui/themes'
import { rem } from 'polished'
import {
  formatQuote,
  phone,
  build,
  edit,
  invertColors,
  spa,
  brightnessLow,
} from '@roo-ui/icons'

theme.colors = {
  ...theme.colors,
  white: '#FFFFFF',
  yellow: '#FFDC00',
  red: '#FF4136',
  blue: '#013379',
  lightBlue: '#007CD6', //#2593cf
  orange: '#FF851B',
  green: '#03864f',
  lightGreen: '#9ACA3C',
  offWhite: '#f9f9f9',
}

theme.colors.brand.primary = theme.colors.lightBlue
theme.colors.brand.secondary = theme.colors.lightGreen
theme.colors.brand.tertiary = theme.colors.blue

// const createMediaQuery = n => `@media screen and (min-width:${n})`

const addAliases = (arr, aliases) =>
  aliases.forEach((key, i) =>
    Object.defineProperty(arr, key, {
      enumerable: false,
      get() {
        return this[i]
      },
    })
  )

export const breakpoints = [32, 40, 48, 64].map(n => n + 'em')
// export const breakpoints = [40, 52, 64].map(n => n + 'em')
// export const mediaQueries = breakpoints.map(createMediaQuery)

const aliases = ['sm', 'md', 'lg', 'xl']

addAliases(theme.breakpoints, aliases)
addAliases(theme.mediaQueries, aliases)

theme.space = [0, 4, 8, 16, 32, 64, 128]

theme.fontWeights = {
  light: 300,
  normal: 400,
  bold: 600,
  black: 900,
}

theme.buttons = {
  default: {
    color: 'white',
    backgroundColor: theme.colors.brand.secondary,
  },
  primary: {
    color: 'white',
    backgroundColor: theme.colors.brand.primary,
  },
}

theme.maxWidths.xs = rem('280px')
theme.maxWidths.sm = rem('640px')
theme.maxWidths.md = rem('768px')
theme.maxWidths.lg = rem('1024px')

theme.icons = {
  ...theme.icons,
  formatQuote,
  phone,
  build,
  edit,
  invertColors,
  spa,
  brightnessLow,
}

// theme.textStyles.label.color
theme.textStyles.labelAlt = { ...theme.textStyles.label, color: 'white' }
export default theme
