// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-success-js": () => import("../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-test-js": () => import("../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-testimonials-js": () => import("../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

