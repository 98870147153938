import React from 'react'
import styled from '@emotion/styled'
// import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types'

// const getMaxWidth = px => parseInt(px) - 0.01
//TODO:can we pull xs/lg aliases from theme definition?
// const breakpoints = props => ({
//   sm: `@media screen and (max-width: ${props.theme.breakpoints.sm})`,
//   md: `@media screen and (min-width: ${
//     props.theme.breakpoints.md
//   }) and (max-width: ${props.theme.breakpoints.lg})`,
//   lg: `@media screen and (min-width: ${props.theme.breakpoints.lg})`,
//   print: `@media print`,
// })
const getMaxWidth = em => parseInt(em) - 0.01
const breakpoints = props => ({
  xs: `@media screen and (max-width: ${getMaxWidth(
    props.theme.breakpoints[0]
  )}em)`,
  sm: `@media screen and (min-width: ${
    props.theme.breakpoints[0]
  }) and (max-width: ${getMaxWidth(props.theme.breakpoints[1])}em)`,
  md: `@media screen and (min-width: ${
    props.theme.breakpoints[1]
  }) and (max-width: ${getMaxWidth(props.theme.breakpoints[2])}em)`,
  lg: `@media screen and (min-width: ${
    props.theme.breakpoints[2]
  }) and (max-width: ${getMaxWidth(props.theme.breakpoints[3])}em)`,
  xl: `@media screen and (min-width: ${props.theme.breakpoints[3]})`,
  print: `@media print`,
})
const hidden = key => props =>
  props[key]
    ? {
        [breakpoints(props)[key]]: {
          display: 'none',
        },
      }
    : null

export const StyledChildren = ({ children, ...props }) => {
  const styledChildren = React.Children.toArray(children).map(child =>
    React.cloneElement(child, props)
  )
  return <>{styledChildren}</>
}

// const Hide = styled(StyledChildren)`
//      ${hidden('sm')} ${hidden('md')} ${hidden('lg')} ${hidden('print')};
// `

const Hide = styled(StyledChildren)`
     ${hidden('xs')} ${hidden('sm')} ${hidden('md')} ${hidden('lg')} ${hidden(
  'xl'
)} ${hidden('print')};
`

Hide.propTypes = {
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  print: PropTypes.bool,
}

Hide.displayName = 'Hide'

export default Hide
