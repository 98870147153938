import React from 'react'
import * as PropTypes from 'prop-types'
import { Flex } from '@roo-ui/components'

//TODO:consider changing name to Box and having flex be default but option to change to block
const alignToFlex = align => {
  switch (align) {
    case 'top':
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'bottom':
    case 'right':
      return 'flex-end'
    default:
      return align
  }
}

const getGrow = grow => {
  if (typeof grow === 'number') {
    return grow
  } else if (grow) {
    return 1
  }

  return 0 // default
}
const getShrink = (shrink, basis) => {
  if (typeof shrink === 'number') {
    return shrink
  } else if (shrink) {
    return 1
  } else if (shrink === false) {
    return 0
  }

  if (basis && basis !== 'auto') {
    return 0
  }

  return 1 // default
}

const getBasis = basis => {
  if (typeof basis === 'number') {
    return `${basis}rem`
  }
  if (!basis) {
    return 'auto'
  }
  return basis
}

const FlexContainer = ({
  children,
  centered,
  col,
  flex,
  grow,
  shrink,
  basis,
  hAlignContent,
  vAlignContent,
  reverse,
  wrap,
  wrapReverse,
  ...rest
}) => {
  const flexCssVal = `${getGrow(grow)} ${getShrink(shrink, basis)} ${getBasis(
    basis
  )}`
  const justify = alignToFlex(col ? vAlignContent : hAlignContent)
  const items = alignToFlex(col ? hAlignContent : vAlignContent)

  let flexDirection = 'row'
  if (col) {
    flexDirection = reverse ? 'column-reverse' : 'column'
  } else if (reverse) {
    flexDirection = 'row-reverse'
  }
  //col and wrap should be default. row, no wrap, reverse should be opt in
  return (
    <Flex
      flexDirection={flexDirection}
      justifyContent={justify}
      alignItems={items}
      flexWrap={wrap ? 'wrap' : wrapReverse && 'wrap-reverse'}
      flex={flexCssVal}
      {...rest}
    >
      {children}
    </Flex>
  )
}
//TODO:same style prop overrides for react-tailwind-ui primitive props like items, justify,
const FlexContainerWrapper = ({ center, ...rest }) => {
  const newProps = {}
  if (typeof center === 'boolean' && center === true) {
    //vertically and horizontally center
    newProps.vAlignContent = 'center'
    newProps.hAlignContent = 'center'
  } else if (typeof center === 'string' && center === 'vertical') {
    newProps.vAlignContent = 'center'
  } else if (typeof center === 'string' && center === 'horizontal') {
    newProps.hAlignContent = 'center'
  }
  //if hAlignContent or vAlignContent was setm override
  return <FlexContainer {...newProps} {...rest} />
}
FlexContainer.propTypes = {
  children: PropTypes.node,
  col: PropTypes.bool,
  vAlignContent: PropTypes.oneOf(['top', 'center', 'bottom']),
  hAlignContent: PropTypes.oneOf(['left', 'center', 'right']),
  grow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  shrink: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  basis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrap: PropTypes.bool,
  wrapReverse: PropTypes.bool,
}
FlexContainer.defaultProps = {
  display: 'flex',
  col: false,
  inline: false,
  basis: '',
  centered: true,
  shrink: false,
}
//TODO: define boxSizing: 'border-box'

//TODO:prop type validation (strict, mutually-exlusive, etc)
//TODO:consier adding explicit "direction", "wrap", etc that override convenience boolean props
//TODO: need to accept "as" to change from div to another element (or create a HOC or css Helper)
// export default styled(FlexContainerWrapper)({}, layout, position, shadow)
export default FlexContainerWrapper
//https://github.com/primer/components/blob/master/src/Flex.js#L14
