import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { borderWidth, borderStyle, width, color } from 'styled-system'

const HorizontalRule = styled.hr`
  ${width}
  ${borderWidth} 
  ${borderStyle}
  ${color}
`
HorizontalRule.defaultProps = {
  width: '8rem',
  borderWidth: '2px',
  borderStyle: 'solid',
}

export default HorizontalRule
