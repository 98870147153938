import PropTypes from 'prop-types'
// import { typography } from 'styled-system'
import styled from '@emotion/styled'
// import { system } from 'styled-system'
import { style } from 'styled-system'
import { Text as TextBase } from '@roo-ui/components'

// const textDecoration = system({ textDecoration: true })
// const textTransform = system({ textTransform: true })

//TODO:shortcut props like bold, underlined, uppercase, etc
// const Text = styled(Box)(
//   {},
//   compose(
//     typography,
//     textDecoration,
//     textTransform
//   )
// )
const textTransform = style({
  prop: 'textTransform',
})
const Text = styled(TextBase)(textTransform)
Text.label = Text.withComponent('label')
Text.span = Text.withComponent('span')
Text.p = Text.withComponent('p')
Text.s = Text.withComponent('s')

export default Text
