import React from 'react'
import { css } from '@emotion/core'
import { Box } from './'

export default class IFrame extends React.Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <Box
        as="iframe"
        w="100%"
        h="100%"
        minH={640}
        absolute
        src={this.props.src}
      />
    )
  }
}
