import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import {
  borderRadius,
  space,
  size,
  height,
  minHeight,
  width,
  display,
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexDirection,
  color,
  backgroundRepeat,
  backgroundPosition,
} from 'styled-system'
import GBackgroundImage from 'gatsby-background-image'
//for some reason, trying to use as= in Flex doesn't work.
const BackgroundImage = styled(GBackgroundImage)(
  props => ({
    backgroundImage: `url(${props.src})`,
    backgroundSize: props.contain ? 'contain' : 'cover',
  }),
  borderRadius,
  space,
  size,
  height,
  minHeight,
  width,
  color,
  display,
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexDirection,
  backgroundRepeat,
  backgroundPosition
)

const numberStringOrArray = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.array,
])

BackgroundImage.propTypes = {
  /** background-image url */
  image: PropTypes.string,
  width: numberStringOrArray,
}

BackgroundImage.defaultProps = {
  // theme: theme
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  contain: false,
}

BackgroundImage.displayName = 'BackgroundImage'

export default BackgroundImage

//TODO:consider using STATIC query and just passing a string/path
