import React from 'react'
import noScroll from 'no-scroll'
import { Location } from '@reach/router'
import { ThemeProvider } from '@roo-ui/themes'
import { Global, css } from '@emotion/core'
import 'normalize.css/normalize.css'
import '@roo-ui/fonts/ciutadella.css'
import LayoutBase from 'components/layout/LayoutBase'
import { graphql, useStaticQuery } from 'gatsby'

import './hamburger.css'
import '../style.css'
// import logoMobile from 'data/img/logo-mobile.png'

import theme from 'theme'
import { Fixed } from 'components/Position'
import { MobileMenu, Flex, Hide, Navbar, Spacer, Text, Image } from 'components'

const menuItems = [
  { name: 'Home', to: '/' },
  { name: 'About', to: '/about' },
  { name: 'Services', to: '/services' },
  // { name: 'Gallery', to: '/gallery' },
  { name: 'Testimonials', to: '/testimonials' },
  { name: 'Contact', to: '/contact' },
  // { name: '404', to: '/404' },
]
console.log(theme.breakpoints.sm, 'theme')
//TODO:turn MobileMenu/Navbar into a "Navigation" component (maybe support in BaseLayout?)
const Layout = ({
  children,
  location,
  hideLayout,
  pageContext: { layout },
}) => {
  if (layout === 'none') {
    return <>{children}</>
  }
  const {
    data: { landingPageExited, mobileMenuOpen },
    set,
  } = React.useContext(LayoutContext)

  const toggleMenuOpen = () => {
    noScroll.toggle()
    set({ mobileMenuOpen: !mobileMenuOpen })
  }

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      navbarLogo: file(relativePath: { eq: "img/logo.png" }) {
        childImageSharp {
          fixed(height: 82) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mobileMenuLogo: file(relativePath: { eq: "img/logo.png" }) {
        childImageSharp {
          fixed(height: 140) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          a {
            text-decoration: none;
            color: inherit;
          }
        `}
      />
      <LayoutBase>
        {!hideLayout && (
          <LayoutBase.Header
            minHeight={[60, null, 128, null, 160]}
            hAlignContent="stretch"
          >
            <Hide md lg xl>
              <Fixed
                right={0}
                top={0}
                zIndex={40}
                bg="transparent"
                bg="brand.secondary"
              >
                <MobileMenu.Hamburger
                  color="white"
                  onClick={toggleMenuOpen}
                  isActive={mobileMenuOpen}
                />
              </Fixed>
            </Hide>
            <Hide xs sm>
              <Navbar zIndex={10} bg="primary">
                <Navbar.Logo>
                  <Image fixed={data.navbarLogo.childImageSharp.fixed} />
                </Navbar.Logo>
                <Spacer />
                <Navbar.Menu>
                  {menuItems.map(({ name, to }) => (
                    <Navbar.MenuItem
                      to={to}
                      key={`${name}:${to}`}
                      active={location.pathname === to}
                      color="brand.primary"
                    >
                      {name}
                    </Navbar.MenuItem>
                  ))}
                </Navbar.Menu>
              </Navbar>
            </Hide>
            <Hide md lg xl>
              {/* <Image src={logoMobile} /> */}
              <MobileMenu
                onMenuItemClicked={toggleMenuOpen}
                isMenuOpen={mobileMenuOpen}
              >
                <Image
                  fixed={data.mobileMenuLogo.childImageSharp.fixed}
                  my={36}
                  mx="auto"
                />
                <Flex
                  grow
                  col
                  wrap
                  hAlignContent="center"
                  vAlignContent="around"
                  p={8}
                >
                  {menuItems.map(({ name, to }) => (
                    <MobileMenu.MenuItem
                      to={to}
                      key={`${name}:${to}`}
                      active={location.pathname === to}
                    >
                      {name}
                    </MobileMenu.MenuItem>
                  ))}
                </Flex>
              </MobileMenu>
            </Hide>
          </LayoutBase.Header>
        )}

        <LayoutBase.Main overflow="hidden">{children}</LayoutBase.Main>
        {!hideLayout && (
          <LayoutBase.Footer
            bg="brand.tertiary"
            py={[2, null, null, 18]}
            col
            color="white"
          >
            <Text textAlign="center" fontSize={['xs', 'sm']}>
              © Precision Sprinkler Systems, Inc. 2019. All rights reserved.
            </Text>
            <Text textAlign="center" fontSize={['xs', 'sm']}>
              Design: Krogel Web Solutions
            </Text>
          </LayoutBase.Footer>
        )}
      </LayoutBase>
    </ThemeProvider>
  )
}

const defaultContextValue = {
  data: {
    // set your initial data shape here
    landingPageExited: false,
    mobileMenuOpen: false,
  },
  set: () => {},
}

export const LayoutContext = React.createContext(defaultContextValue)

class LayoutContextProvider extends React.Component {
  constructor() {
    super()
    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    }
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  render() {
    return (
      <LayoutContext.Provider value={this.state}>
        {this.props.children}
      </LayoutContext.Provider>
    )
  }
}
const EnhancedLayout = ({ children, ...rest }) => (
  <LayoutContextProvider>
    <Location>
      {({ location }) => {
        return (
          <Layout location={location} {...rest}>
            {children}
          </Layout>
        )
      }}
    </Location>
  </LayoutContextProvider>
)
// export default Layout
export default EnhancedLayout
