import React from 'react'
// import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { Text, Flex, Box } from './'

//TODO:we can use prop activeClassName or activeStyle for menu (may not help anything)

//TODO:try using the gatsby link isActive api...
//todo:see if there is a helper func like
//hover[bg-black text-white]
const activeMenuItemProps = {
  fontWeight: 'black',
  bg: 'orange-lightest',
  border: 'orange-lightest',
  bx: 8,
  by: 4,
}
const MenuItem = ({ children, to, active, ...rest }) => {
  const activeProps = active ? activeMenuItemProps : {}
  return (
    <Flex
      mt={4}
      mr={12}
      px={2}
      font-hover="black"
      css={css`
        /* border-style: ${active ? 'solid' : 'none'}; */
        transition: all 200ms ease-in-out;
      `}
      {...rest}
      {...activeProps}
      vAlignContent="center"
      justifyContent="center"
      backgroundColor={active ? 'brand.primary' : 'none'}
      color={active ? 'white' : 'brand.primary'}
    >
      <Text
        fontFamily="'Titillium Web', sans-serif"
        fontWeight="bold"
        letterSpacing="wide"
        textTransform="uppercase"
      >
        <Link to={to} {...rest}>
          {children}
        </Link>
      </Text>
    </Flex>
  )
}

const Logo = ({ children }) => (
  <Box m="auto">
    <Link to="/" key="home">
      {children}
    </Link>
  </Box>
)
const Menu = ({ children }) => (
  <Flex as="nav" hAlignContent="right" vAlignContent="center">
    {children}
  </Flex>
)

//TODO:seperatre out uppercase styling etc
const NavBar = ({ children, ...rest }) => {
  return (
    <Flex
      role="navigation"
      grow
      uppercase
      py={[0, 0, 0, 16, 32]}
      px={4}
      {...rest}
    >
      <Flex grow>{children}</Flex>
    </Flex>
  )
}

NavBar.Logo = Logo
NavBar.Menu = Menu
NavBar.MenuItem = MenuItem

export default NavBar
//TODO: look up with importing from "emotion" is still needed outside of incremental migration  in emotion 10 (vs @emotion/core)
